@import "variables.scss";
.WebsiteDesigningPage {
    &-Heading{
      @include heading;
      margin: 100px auto 40px auto;
      text-align: center;
      @include mobile {
        margin: 30px auto 20px;
      }
    }        
    &-Content{
        @include container;
        margin-block: 40px;   
        @include mobile {
            margin-block: 20px;
        }
        &:first-child{
            text-align: center;
        }
    }
    &-Items{
        background-color: var(--color-light);
        margin-top: 80px;
        padding-bottom: 10px;
        @include mobile {
            margin-top: 30px;
        }
    }
    &-SubHeading {
        @include heading;
        text-align: center;
        padding: 100px 30px;
        @include mobile {
            padding: 30px;
            line-height: 25px;
        }
    }
    &-SubItems{
        @include container;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
        text-align: center;
        @include mobile {
        grid-template-columns: repeat(1, 1fr);
        gap: 15px;
        }
    }  
    &-Item {
        @include shadow;
        text-align: center;
        padding:30px;
        @include mobile {
            padding: 15px;
        }
        span{
            color: var(--color-primary);
        }
        &-Img{
            margin: 20px;
            @include mobile {
                margin: 10px;
            }
            img {
                width: 70px;
                height: auto;
            }
        }
        &-Heading{
            font-size: 30px;
            font-weight: 500;
            margin:20px;
            @include mobile {
                font-size: 20px;
                margin: 10px;
            }
        }
        &-Content{
            margin: 20px;
            font-weight: 400;
            @include mobile {
                margin: 10px;
            }
        }
    }   
}


