@import "variables.scss";
.ContactusPage {
    background-color: var(--color-light);  
    padding-block: 100px 20px;
    @include mobile {
        padding-block: 20px;
    }
    &-Heading {
        font-size: 30px;
        margin-bottom: 20px;
        font-weight: 600;
        text-align: center;
        @include mobile {
            font-size: 20px;
            margin-bottom: 10px;
        }
    }
    &-SubHeading {
        font-size: 23px;
        margin-bottom: 30px;
        font-weight: 500;
        text-align: center;
        color: var(--color-primary);
        @include mobile {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 10px;
        }
    }
    &-Content {
        text-align: center;
        width: 100%;
        max-width: 700px;
        margin-inline: auto;
        font-size: 18px;
        @include mobile {
            font-size: 12px;
        }
    }
}