@import "variables.scss";
.MobileAppDevelopmentPage {
    &-Container {
        @include container;
    }
    &-Heading {
        @include heading;
        margin-block: 100px 30px;
        text-align: center;
        @include mobile {
            margin-block: 30px 10px;
            line-height: 25px;
        }
    }
    &-Content {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 20px;
    }
    &-SubHeading{
        color: var(--color-primary);
        text-align: center;
        margin-block: 50px 30px;
        font-size: 23px;
        font-weight: 500;
        @include mobile {
            margin-block: 30px 20px;
            line-height: 25px;
            font-size: 18px;
        }
    }
    &-Services {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-bottom: 100px;
        @include mobile {
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;
            margin-bottom: 30px;
        }
    }
    &-Service {
        @include shadow;
        padding: 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
        &:last-child {
            grid-column: 1 / -1;
        }
        &-Icon {
            width: 70px;
            height: auto;
        }
        &-Heading {
            color: var(--color-primary);
            font-size: 18px;
            font-weight: 500;
            margin-block: 10px 20px;
        }
    }
    &-ServicesList {
        margin-bottom: 100px;
        @include mobile {
            margin-bottom: 30px;
        }
        &-Item {
            padding-block: 15px;
            border-bottom: 1px solid var(--color-primary-light);
            span {
                color: var(--color-primary);
                display: inline-block;
                margin-right: 10px;
            }
        }
    }
}