@import "variables.scss";
.WebsiteDevelopmentPage {
    &-Container {
        @include container;
    }
    &-Heading {
        @include heading;
        margin-block: 100px 30px;
        text-align: center;
        @include mobile {
            margin-block: 30px 20px;
        }
    }
    &-Content {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 20px;
    }
    &-ServicesList {
        margin-bottom: 100px;
        @include mobile {
            margin-bottom: 30px;
        }
        &-Item {
            padding-block: 15px;
            border-bottom: 1px solid var(--color-primary-light);
            span {
                color: var(--color-primary);
                display: inline-block;
                margin-right: 10px;
            }
        }
    }
}