@import "variables.scss";
.Header {
    &-Topbar {
        background-color: var(--color-primary);
        padding-block: 5px;
        &-Wrapper {
            max-width: var(--max-content-width);
            margin-inline: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include mobile {
                flex-direction: column;
                gap: 5px;
            }
        }
        &-Left {
            display: flex;
            align-items: center;
            gap: 18px;
            >a {
                display: flex;
                align-items: center;
                color: var(--color-white);
                gap: 5px;
                &:first-child::after {
                    content: "|";
                    position: absolute;
                    right: -10px;
                    top: 0;
                }
            }
        }
        &-Right {
            display: flex;
            align-items: center;
            gap: 10px;
            a {
                color: var(--color-white);
            }
        }
    }
    &-Main {
        @include shadow;
        padding-blocK: 10px;
        &-Wrapper {
            max-width: var(--max-content-width);
            margin-inline: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    &-Navigation {
        display: flex;
        align-items: center;
        gap: 40px;
        @include mobile {
            flex-direction: column;
            position: absolute;
            right: 0;
            z-index: 1;
            background-color: var(--color-white);
            align-items: stretch;
            gap: 10px;
            @include shadow;
            display: none;
            &-Open {
                display: block;
            }
        }
        &-Link {
            color: var(--color-black);
            padding: 10px;
            display: block;
            font-weight: 500;
        }
        &-Item {

            &:hover {
                .Header-Navigation-Item-DropDown {
                    display: flex;
                    height: auto;
                }
            }
            &-DropDown {
                @include shadow;
                background: var(--color-white);
                position: absolute;
                min-width: 200px;
                top: 37px;
                flex-direction: column;
                display: none;
                height: 0;
                overflow: hidden;
                transition: all 0.3s linear;
                z-index: 1;
                @include mobile {
                    position: relative;
                    display: flex;
                    // height: unset;
                    position: unset;
                    margin-inline-start: 20px;
                    box-shadow: unset;
                }
                li {
                    a {
                        color: var(--color-black);
                        padding: 10px;
                        display: block;
                    }
                }
                
            }
            &:last-child {
                background-color: var(--color-primary);
                .Header-Navigation-Link {
                    color: var(--color-white);
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    @include mobile {
                        justify-content: center;
                    }
                }
            }
        }
    }
    &-Logo {
        a {
            display: block;
            img {
                width: 160px;
                height: auto;
                @include mobile {
                    width: 150px;
                }
            }
        }
        @include mobile {
            max-width: 150px;
        }
    }
    &-MobileMenu {
        display: none;
        @include mobile {
            display: block;
            cursor: pointer;
            font-size: 25px;
        }
    }
}