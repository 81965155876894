@import "variables.scss";
.Banner {
    background-image: url(../../Assets/images/homebanner.webp);
    @include mobile {
        background-size: cover;
        background-repeat: no-repeat;
    }
    &-Container {
        @include container;
        display: flex;
        min-height: 500px;
        align-items: center;
        justify-content: flex-start;
        @include mobile {
            min-height: 145px;
        }
        h1 {
            font-size: 70px;
            font-weight: 900;
            line-height: 70px;
            color: var(--color-white);
            span {
                color: var(--color-primary);
            }
            @include mobile {
                font-size: 30px;
                line-height: 35px;
            }
        }
    }
}