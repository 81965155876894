@mixin desktop {
    @media screen and (min-width: 1024) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: 811px) and (max-width: 1023px) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: 810px) {
        @content;
    }
}