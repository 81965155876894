@import "variables.scss";
.Footer {
    background-color: var(--color-black);
    color: var(--color-light);
    padding-block: 0 100px;
    @include mobile {
        padding-bottom: 20px;
    }
    &-Top {
        width: 100%;
        object-fit: cover;
        object-position: bottom center;
        margin-bottom: 0;
        display: block;
        background-color: var(--color-light);
        height: auto;
    }
    &-Container {
        @include container;
    }
    &-Intro{
        text-align: center;
        font-size: 20px;
        margin-inline: auto;
        max-width: 450px;
        margin-bottom: 30px;
        @include mobile {
            font-size: 15px;
            padding-top: 15px;
            margin-bottom: 15px;
        }
    }
    &-Social, &-Phone, &-Email, &-Links, &-Copyrights {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-size: 35px;
        a {
            color: var(--color-light);
        }
        margin-bottom: 30px;
        @include mobile {
            margin-bottom: 15px;
        }
    }
    &-Phone, &-Email {
        font-size: 18px;
        color: var(--color-light);
    }
    &-Copyrights, &-Links {
        font-size: 15px;
        margin-bottom: 15px;
        @include mobile {
            text-align: center;
            font-size: 13px;
            line-height: 16px;
        }
    }
}