@import "variables.scss";
.DigitalMarketingPage {
    &-Container {
        @include container;
        p {
            margin-bottom: 20px;
            &:first-of-type {
                @include mobile {
                    margin-top: 15px;
                }
            }
        }
    }
    &-Heading {
        @include heading;
        margin-block: 100px 30px;
        text-align: center;
        @include mobile {
            margin-block: 30px 0;
            line-height: 25px;
        }
    }
    &-CheckList {
        // margin-top: 
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 100px;
        @include mobile {
            margin-bottom: 30px;
            gap: 10px;
            justify-content: flex-start;
        }
        > div {
            width: 300px;
            text-align: center;
            color: var(--color-primary);
            display: flex;
            align-items: center;
            gap: 10px;
            span {
                color: var(--color-black);
            }
        }
    }
    &-Services {
        background-color: var(--color-light);
        padding-top: 1px;
    }
    &-ServicesContent {
        --gap: 50px;
        padding-block: 100px;
        @include mobile {
            --gap: 38px;
            padding-block: 10px;
        }
        // background-color: var(--color-white);
        &-Container {
            @include container;
        }
        &-SubHeading {
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            @include mobile {
                margin-bottom: 10px;
            }
        }
        &-Heading {
            @include heading;
            text-align: center;
            margin-bottom: 30px;
        }
        &-Start, &-End {
            width: 10px;
            height: 10px;
            background-color: var(--color-dark);
            border-radius: 50%;
            left: 50%;
            transform: translateX(-50%);
            @include mobile {
                left: auto;
                transform: unset;
                right: 12px;
                position: absolute;
            }
        }
        &-Items {
            display: flow-root;
            padding-block: 30px;
            @include mobile {
                padding-block: 15px;
            }
            &::after {
                content: "";
                width: 5px;
                background-color: var(--color-dark);
                display: block;
                clear: both;
                height: 100%;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                @include mobile {
                    left: auto;
                    transform: unset;
                    right: 14px;
                }
            }
        }
        &-Item {
            clear: both;
            width: calc(50% - var(--gap));
            margin-bottom: 50px;
            border-radius: 10px;
            @include shadow;
            @include mobile {
                width: 85%;
                margin-bottom: 20px;
            }
            &:nth-child(even) {
                float: left;
                border-right: 3px solid var(--color-primary);
                @include mobile {
                    float: none;
                }
                .DigitalMarketingPage {
                    &-ServicesContent {
                        &-Item {                            
                            &-Label {
                                right: calc(-100% - var(--gap));
                                text-align: start;
                            }
                            &-Icon {
                                right: calc(var(--gap) / 2 - var(--gap) * 2);
                            }
                            &-Arrow {
                                right: -14px;
                                border-bottom-color: transparent;
                                border-left-color: transparent;
                            }
                        }
                    }
                }
            }
            &:nth-child(odd) {
                float: right;
                border-left: 3px solid var(--color-primary);
                @include mobile {
                    border-left: none;
                    border-right: 3px solid var(--color-primary);
                    float: none;
                }
                .DigitalMarketingPage {
                    &-ServicesContent {
                        &-Item {
                            &-Label {
                                text-align: end;
                                left: calc(-100% - var(--gap));
                                width: calc(100% - var(--gap));
                                @include mobile {
                                    display: none;
                                }
                            }
                            &-Icon {
                                left: calc(var(--gap) / 2 - var(--gap) * 2);
                                @include mobile {
                                    left: auto;
                                    right: calc(var(--gap) / 2 - var(--gap) * 2);
                                }
                            }
                            &-Arrow {
                                left: -3px;
                                border-top-color: transparent;
                                border-right-color: transparent;
                                @include mobile {
                                    left: auto;
                                    position: absolute;
                                    right: 0;
                                    right: -14px;
                                    border-bottom-color: transparent;
                                    border-left-color: transparent;
                                    border-top-color: var(--color-primary);
                                    border-right-color: var(--color-primary);
                                }
                            }
                        }
                    }
                }
            }
            &-Label {
                position: absolute;
                width: calc(100% - var(--gap));
                top: calc(var(--gap) / 2);
                transform: translateY(-8px);
                color: var(--color-primary);
                font-size: 20px;
                font-weight: 600;
                @include mobile {
                    display: none;
                }
            }
            &-Icon {
                position: absolute;
                background-color: var(--color-primary);
                border: 3px solid var(--color-dark);
                border-radius: 50%;
                z-index: 1;
                width: calc(var(--gap) - 6px);
                height: calc(var(--gap) - 6px);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 23px;
                color: var(--color-white);
                @include mobile {
                    left: auto;
                    font-size: 17px;
                }
            }
            &-Arrow {
                position: absolute;
                width: 10px;
                height: 10px;
                background-color: var(--color-white);
                z-index: 1;
                transform: rotate(45deg) translateX(-50%);
                top: 20%;
                border: 3px solid var(--color-primary);
            }
            &-Content {
                background-color: var(--color-white);
                padding: 20px;
                border-radius: 10px;
                @include mobile {
                    padding: 15px;
                }
                &-Heading {
                    font-weight: 600;
                    margin-bottom: 10px;
                    font-size: 20px;
                    color: var(--color-primary);
                    @include mobile {
                        font-size: 17px;
                        line-height: 20px;
                    }
                }
                &-Text {
                    font-size: 16px;
                    @include mobile {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 17px;
                    }
                }
            }
        }
    }
}