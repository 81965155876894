@import "variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    --color-white: #{$white};
    --color-black: #{$black};
    --color-primary: #{$primary};
    --color-primary-dark: #{darken($primary, 30)};
    --color-primary-light: #{lighten($primary, 30)};
    --color-light: #{$light};
    --color-dark: #{darken($light, 20)};
    --max-content-width: #{$maxcontentwidth};
    @include mobile {
        --max-content-width: #{$maxcontentwidthmobile};
    }
}

* {
    padding: 0;
    margin: 0;
    text-decoration: none;
    position: relative;
    list-style-type: none;
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    @include mobile {
        font-size: 13px;
        line-height: 15px;
    }
}