@import "variables.scss";
.AboutusPage{
    &-Aboutus {
        padding-block: 100px;
        background-color: var(--color-light);
        @include mobile {
            padding-block: 30px;
        }
        &-Container {
            @include container;
            display: flex;
            align-items: center;
            gap: 40px;
            @include mobile {
                flex-direction: column;
                gap: 20px;
            }
        }
        &-Image {
            flex: 4;
            img {
                width: 100%;
                height: auto;
            }
        }
        &-Content {
            flex: 6;
        }
        &-Heading {
            @include heading;
            margin-bottom: 30px;
            @include mobile {
                margin-bottom: 15px;
                text-align: center;
            }
            span {
                color: var(--color-primary);
            }
        }
    }
    &-Services{
        background-color: var(--color-white);
        &-Container {
            @include container;
            padding-block: 100px;
            @include mobile {
                padding-block: 30px;
            }
        }
        &-SubHeading {
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            @include mobile {
                margin-bottom: 10px;
            }
        }
        &-Heading {
            @include heading;
            text-align: center;
            margin-bottom: 30px;
        }
        &-Items {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            margin-bottom: 30px;
            @include mobile {
                grid-template-columns: repeat(2, 1fr);
                justify-content: center;
            }
        }
        &-Item {
            @include shadow;
            padding: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            background-color: var(--color-light);
            @include mobile {
                padding: 15px;
                align-items: flex-start;
                gap: 10px;
            }
            &-Image {
                width: 70px;
                height: auto;
            }
            &-Heading {
                font-size: 22px;
                font-weight: 500;
                color: var(--color-primary);
                @include mobile {
                    font-size: 15px;
                }
            }
            // &-Item {
            //     @include mobile {
            //         font-size: 12px;
            //     }
            // }
        }
        &-Text {
            font-weight: 500;
            text-align: center;
            span {
                color: var(--color-primary);
            }
        }
    }
}